<template>
  <div class="page">
    <div class="account_infomation">
      <div class="account">
        <p>账户信息</p>
        <!-- <span>16277768990689080</span> -->
      </div>
      <!-- 账户信息 -->
      <div class="infomation_form">
        <van-cell title="账户余额:" :value="amount+'元'" />
        <van-cell title="待结算金额:" :value="user_info.pending_money+'元'" />
        <van-cell title="可提现金额:" :value="user_info.money+'元'" />
        <van-cell title="累计返利:" :value="user_info.total_commission+'元'" />
        <van-button class="withdraw_btn" @click="get_withdraw">申请提现</van-button>
        <van-button class="my_order" @click="get_order">我的订单</van-button>
        <van-button class="withdraw_record" @click="get_record">提现记录</van-button>
      </div>
      <!-- 提示 -->
      <div class="withdraw_tip">
        <p>
          1、可提现余额满1元即可申请提现，无需手续费
          <br />2、请确保您的支付宝账号和姓名一致
          <br />3、每月25号结算上个月的已收货订单
          <br />4、如想使用其他收款方式请联系客服处理
          <br />5、如有其它问题或疑问，请及时联系客服处理
          <br />
          6、客服微信: {{customer_account}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { NoticeBar, Icon, Cell, Field, Button } from "vant";
import PinkBtn from "@/components/PinkBtn";
import ls from "@/libs/localstore";
import { getUserInfo } from "@/api/user";
import { getWithdrawTimes, getCustomerInfo } from "@/api/wallet";
import MenuBar from "@/components/MenuBar";

Vue.use(NoticeBar).use(Icon).use(Cell).use(Field).use(Button);

export default {
  name: "Personal",
  components: {
    PinkBtn,
    MenuBar,
  },
  data() {
    return {
      amount: ls.getUserInfo().amount,
      user_info: {
        pending_money: 0,
        money: 0,
        total_commission: 0,
      },
      customer_account: "",
    };
  },
  mounted() {
    this._loadUserInfo();
    this._getWithdrawTimes();
    this._getCustomerInfo();
  },
  methods: {
    //获取客服微信号
    _getCustomerInfo() {
      getCustomerInfo().then((res) => {
        if (res.errCode == 0) {
          this.customer_account = res.data.customer_account;
        }
      });
    },
    //获取用户数据
    _loadUserInfo() {
      getUserInfo({
         
      }).then((res) => {
        if (res.errCode == 0) {
          ls.setUserInfo(res.data);
        }
      });
    },
    //获取可提现金额
    _getWithdrawTimes() {
      let params = {
         
      };
      getWithdrawTimes(params)
        .then((res) => {
          if (res.errCode == 0) {
            this.user_info = res.data;
          }
        })
        .catch((err) => {});
    },
    get_withdraw() {
      this.$router.push("/withdrawCashApply");
    },
    get_record() {
      this.$router.push("/withdrawCash");
    },
    get_order() {
      this.$router.push("/myOrder");
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  background: #f6f6f6;
}
.account_infomation {
  .account {
    margin-bottom: 10px;
    width: 375px;
    height: 100px;
    background: linear-gradient(
      270deg,
      rgba(255, 108, 115, 1),
      rgba(255, 85, 149, 1)
    );
    color: rgba(255, 255, 255, 1);
    font-family: PingFang SC;
    padding: 24px 0 27px 18.5px;
    box-sizing: border-box;
    p {
      line-height: 19px;
      font-size: 19px;
      font-weight: 500;
      margin-bottom: 14px;
    }
    span {
      font-size: 15px;
      line-height: 15px;
      font-weight: 400;
    }
  }
  .infomation_form {
    background-color: #fff;
    padding-bottom: 25px;
    .van-cell {
      padding: 17px 24px;
      box-sizing: border-box;
      font-size: 15px;
      .van-cell__value {
        font-size: 15px;
        color: #333333;
      }
    }
    .van-cell::after {
      width: 337px;
      left: 19px;
      height: 1px;
      background: rgba(241, 241, 241, 1);
    }
    .van-button {
      width: 337px;
      border: 1px solid rgba(255, 97, 132, 1);
      color: #ff6184;
      font-size: 17px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin: 15px 19px 0;
      border-radius: 5px;
    }
    .withdraw_btn {
      margin-top: 30px;
      background: linear-gradient(
        270deg,
        rgba(255, 108, 115, 1),
        rgba(255, 85, 149, 1)
      );
      border: none;
      color: #ffffff;
    }
  }
  .withdraw_tip {
    margin: 10px 0 50px;
    padding: 15px 18.5px 16px;
    background: rgba(253, 247, 224, 1);
    p {
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 400;
      color: rgba(94, 87, 71, 1);
      line-height: 20px;
    }
  }
}
</style>
